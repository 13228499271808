import './assets/global.css';
import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#8c52ff", // Roxo vibrante para o scrollbar
        },
      },
      palette: {
        primary: { main: "#190c33" }, // Azul escuro como cor primária
        secondary: { main: "#8c52ff" }, // Roxo vibrante como cor secundária
        accent: { main: "#c4fe03" }, // Verde limão como cor de destaque (use com moderação)
        lightAccent: { main: "#c4a9f2" }, // Lilás claro
        background: {
          default: "#ffffff", // Fundo branco
          paper: "#f5f5f5", // Cinza bem claro para elementos de papel
        },
        text: {
          primary: "#190c33", // Texto principal em azul escuro
          secondary: "#666666", // Texto secundário em cinza
        },
      },
      typography: {
        fontFamily: "'Poppins', sans-serif",
        h1: { fontWeight: 700 },
        h2: { fontWeight: 700 },
        h3: { fontWeight: 700 },
        h4: { fontWeight: 700 },
        h5: { fontWeight: 700 },
        h6: { fontWeight: 700 },
        body1: { fontWeight: 400 },
        body2: { fontWeight: 400 },
      },
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

export default App;