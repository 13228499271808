import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Button } from '@material-ui/core';
import api, { instagramApi } from '../../services/api'; 

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

function InstagramIntegration() {
  const classes = useStyles();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    checkConnection();
  }, []);

  const checkConnection = async () => {
    try {
      const response = await api.get('/instagram/status');
      setConnected(response.data.connected);
    } catch (err) {
      console.error("Erro ao verificar conexão com Instagram", err);
    }
  };

  const handleConnect = async () => {
    try {
      const response = await instagramApi.connect();
      console.log('Resposta da API:', response.data);
      if (response.data.authUrl) {
        window.open(response.data.authUrl, '_blank');
      } else {
        console.error('URL de autenticação não recebida');
      }
    } catch (err) {
      console.error("Erro ao conectar com Instagram", err);
    }
  };

  return (
    <Paper className={classes.paper}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Integração com Instagram
      </Typography>
      {connected ? (
        <Typography>Conectado ao Instagram</Typography>
      ) : (
        <Button variant="contained" color="primary" onClick={handleConnect}>
          Conectar ao Instagram
        </Button>
      )}
    </Paper>
  );
}

export default InstagramIntegration;