import axios from "axios";
import { getBackendUrl } from "../config";

const api = axios.create({
	baseURL: getBackendUrl(),
	withCredentials: true,
});
export const instagramApi = {
	getStatus: () => api.get('/instagram/status'),
	connect: () => api.post('/instagram/connect'),
	getMessages: () => api.get('/instagram/messages'),
	// Adicione outras funções conforme necessário
  };
  
export default api;
